import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import useUserAuth, { UserAuthProps } from '@/hooks/useUserAuth';
import MabraLogo from '@/mabra/public/image/mabra-logo.svg';
import BlobLogoDefault from '@/mabra/public/image/navigation/blob-logo-default.svg';
import Feelgood from '@/mabra/public/image/navigation/blob-logo-feelgood.svg';
import Medicin from '@/mabra/public/image/navigation/blob-logo-medicin.svg';
import NyttigMat from '@/mabra/public/image/navigation/blob-logo-nyttig-mat.svg';
import Psykologi from '@/mabra/public/image/navigation/blob-logo-psykologi.svg';
import Relationer from '@/mabra/public/image/navigation/blob-logo-relationer.svg';
import SenasteNytt from '@/mabra/public/image/navigation/blob-logo-senaste-nytt.svg';
import ModeOchSkonhet from '@/mabra/public/image/navigation/blob-logo-skonhet.svg';
import Traning from '@/mabra/public/image/navigation/blob-logo-traning.svg';
import MotherhoodLogo from '@/motherhood/public/image/motherhood-logo.svg';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useAppState } from 'lib/hooks/useAppState';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { kebabToPascal } from 'lib/utils/kebabToPascal';
import { showOneTrustConsent } from 'lib/utils/oneTrust';
import { useRouter } from 'next/router';
import { ElementType } from 'react';

const logoBlobs: Record<string, ElementType> = {
  Feelgood,
  Medicin,
  ModeOchSkonhet,
  NyttigMat,
  Psykologi,
  Relationer,
  SenasteNytt,
  Traning,
};

const menu: StandaloneNavigationProps['menu'] = (
  <>
    <Navigation.Link href="/halsa" content="Hälsa" aria-label="Länk till kategorin Hälsa" />
    <Navigation.Link href="/traning" content="Träning" aria-label="Länk till kategorin Träning" />
    <Navigation.Link href="/medicin" content="Medicin" aria-label="Länk till kategorin Medicin" />
    <Navigation.Link href="/psykologi" content="Psykologi" aria-label="Länk till kategorin Psykologi" />
    <Navigation.Link href="/video/" content="Video" aria-label="Länk till Måbras Video-sida" />
    <Navigation.Link href="/bloggar" content="Bloggar" aria-label="Länk till Måbras Bloggar" />
  </>
);

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group>
    <Navigation.SoMe.Headline>Följ MåBra</Navigation.SoMe.Headline>
    <Navigation.SoMe href="https://www.facebook.com/tidningenmabra/" name="facebook" aria-label="Länk till Facebook" />
    <Navigation.SoMe href="https://www.instagram.com/mabra_com/" name="instagram" aria-label="Länk till Instagram" />
    <Navigation.SoMe href="https://www.pinterest.se/mabracom/" name="pinterest" aria-label="Länk till Pinterest" />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAqBwgKMJiyowsw27y7Aw?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        className: '!pt-0.5 !pb-0.75',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Navigation.SoMe.Group>
);

type GetDrawerProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logInUrl' | 'logOutUrl'>;
const getDrawer = ({ isUserLoggedIn, logInUrl, logOutUrl }: GetDrawerProps) =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          { href: '/halsa', content: 'Hälsa', options: { 'aria-label': 'Länk till kategorin Hälsa' } },
          { href: '/traning', content: 'Träning', options: { 'aria-label': 'Länk till kategorin Träning' } },
          { href: '/medicin', content: 'Medicin', options: { 'aria-label': 'Länk till kategorin Medicin' } },
          { href: '/psykologi', content: 'Psykologi', options: { 'aria-label': 'Länk till kategorin Psykologi' } },
          { href: '/relationer', content: 'Relationer', options: { 'aria-label': 'Länk till kategorin Relationer' } },
        ],
      },
      {
        links: [
          { href: '/nyttig-mat', content: 'Nyttig mat', options: { 'aria-label': 'Länk till kategorin Träning' } },
          { href: '/fraga-experterna', content: 'Fråga experterna', options: { 'aria-label': '' } },
          {
            href: '/senaste-nytt',
            content: 'Senaste nytt',
            options: { 'aria-label': 'Länk till kategorin Senaste nytt' },
          },
          {
            href: '/video/',
            content: (
              <>
                Video
                <Icon name="externalLink" />
              </>
            ),
            options: { 'aria-label': 'Länk till Måbras Video-sida' },
          },
          { href: '/reportage', content: 'Reportage', options: { 'aria-label': 'Länk till kategorin Reportage' } },
          {
            href: '/mode-och-skonhet',
            content: 'Mode & skönhet',
            options: { 'aria-label': 'Länk till kategorin Mode och skönhet' },
          },
        ],
      },
      {
        links: [
          { href: '/resor', content: 'Resor', options: { 'aria-label': 'Länk till kategorin Resor' } },
          { href: '/feelgood', content: 'Feelgood', options: { 'aria-label': 'Länk till kategorin Feelgood' } },
          {
            href: 'https://www.motherhood.se',
            content: (
              <>
                <MotherhoodLogo className="mr-3 w-[34px] text-[#DB1717]" />
                Motherhood
                <Icon name="externalLink" />
              </>
            ),
            options: { 'aria-label': 'Länk till Motherhood.se' },
          },
        ],
      },
      {
        links: [
          [
            { content: 'Mer', as: 'span' },
            {
              href: 'https://www.prenumerera.se/tidningar/mabra?utm_source=mabra.com&utm_medium=menu&utm_campaign=mabra',
              target: '_blank',
              content: 'Prenumerera',
              options: { 'aria-label': 'Länk till att prenumerera på Måbra' },
            },
            {
              href: '/halsoverktyg/nyhetsbrev',
              content: 'Nyhetsbrev',
              options: { 'aria-label': 'Länk till att prenumerera på Måbras nyhetsbrev' },
            },
            { href: '/kontakt', content: 'Kontakt', options: { 'aria-label': 'Länk till hur du kan kontakta Måbra' } },
            { href: '/om-oss', content: 'Om oss', options: { 'aria-label': 'Länk till information om oss' } },
            {
              href: '/om-cookies',
              content: 'Om cookies',
              options: { 'aria-label': 'Länk till information om cookies på Måbra' },
            },
            {
              href: '#',
              content: 'Hantera preferenser',
              options: {
                'aria-label': 'Länk till att hantera inställningar för cookies på Måbra',
                onClick: (e) => {
                  showOneTrustConsent();
                  e.preventDefault();
                },
              },
            },
            {
              href: '/alla-amnen',
              content: 'Alla ämnen',
              options: {
                'aria-label': 'Länk till Alla ämnen sidan där alla kategorier på Måbra listas i alfabetisk ordning',
              },
            },
            {
              href: '/vara-skribenter',
              content: 'Våra skribenter',
              options: { 'aria-label': 'Länk till information om skribenterna på Måbra' },
            },
            {
              href: '/creative-studio',
              content: 'Creative studio',
              options: { 'aria-label': 'Länk till information om Måbras annonssamarbeten' },
            },
            isUserLoggedIn
              ? { href: logOutUrl, content: 'Logga ut', options: { 'aria-label': 'Länk till logga ut från kontot' } }
              : { href: logInUrl, content: 'Logga in', options: { 'aria-label': 'Länk till logga in på konto' } },
          ],
        ],
      },
    ],
  });

export const MabraStandaloneNavigation: typeof StandaloneNavigation = () => {
  const [{ affiliate, pageType, publishedUrl }] = useAppState();
  const { isUserLoggedIn, logOutUrl, logInUrl } = useUserAuth();
  const { asPath } = useRouter();

  const showBlob = publishedUrl === '/index' || pageType === 'article';

  const BlobLogo = logoBlobs[kebabToPascal(affiliate ?? '')] ?? BlobLogoDefault;

  const {
    Navigation: {
      $drawer: { isVisible: isMenuOpened, show: showMenu },
    },
  } = useComponentsState();

  const controls: StandaloneNavigationProps['controls'] = (
    <>
      <Navigation.Button
        link={{
          href: 'https://www.prenumerera.se/tidningar/mabra?utm_source=mabra.com&utm_medium=menu&utm_campaign=mabra',
          target: '_blank',
        }}
        options={{ colors: 'secondary', variant: 'alternative' }}
        content="Prenumerera"
        aria-label="Länk till att prenumerera på Måbra"
      />

      {!(isUserLoggedIn && asPath === '/mina-sidor') && (
        <Navigation.Button
          link={{
            href: isUserLoggedIn ? '/mina-sidor' : logInUrl,
            target: isUserLoggedIn ? '_self' : '_blank',
          }}
          content={isUserLoggedIn ? 'Min Sida' : 'Logga in'}
          aria-label={isUserLoggedIn ? 'Länk till gå till min sida' : 'Länk till logga in på konto'}
        />
      )}

      {!isMenuOpened && (
        <Icon
          name="search"
          options={{ size: 'medium', onClick: showMenu, className: 'self-center cursor-pointer hidden md:block' }}
          aria-label="Knapp för att öppna sökfunktionen på Måbra"
        />
      )}

      <button type="button" title="Cookies" onClick={showOneTrustConsent}>
        <Icon
          name="cookie"
          options={{ className: 'text-cherry-blossom-500', size: 'medium' }}
          aria-label="Knapp för att öppna inställningar för cookies på Måbra"
        />
      </button>
    </>
  );

  const logo: StandaloneNavigationProps['logo'] = (
    <>
      {showBlob && !isMenuOpened && (
        <BlobLogo className="pointer-events-none absolute -top-0.5 left-0 h-[67px] w-[146px] sm:w-[202px]" />
      )}
      <MabraLogo className="relative z-10 !h-[30px] w-20 sm:w-[115px]" />
    </>
  );

  const drawer = getDrawer({ isUserLoggedIn, logInUrl, logOutUrl });

  return (
    <StandaloneNavigation
      {...{
        menu,
        controls,
        logo,
        drawer,
        soMe,
      }}
      options={{
        $search: { $submit: { options: { size: 'medium' } } },
        $logo: { options: { colors: 'none' } },
        $bar: { $group: { colors: 'none' } },
      }}
    />
  );
};
